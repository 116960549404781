import {useEffect, useState} from 'react';
import WalletDisplay from './wallet-display';
import Cookies from 'js-cookie';

const Squares = () => {
    const session_token = Cookies.get('c8_session_token');

    const [iframeSrc, setIframeSrc] = useState('');
    const [showWallet, setShowWallet] = useState(false);
    const [userAccount, setUserAccount] = useState({
        id: '',
        username: '',
        profile_pic: '',
        wallet: {
            coins: '',
            rubies: '',
        },
    });
    useEffect(() => {
        const fetchAccount = async () => {
            const response = await fetch(
                `${process.env.API_URL}/account/${session_token}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.API_KEY as string,
                    },
                }
            );

            if (response.status === 200) {
                const data = await response.json();
                setUserAccount({
                    username: data.data.username,
                    profile_pic: data.data.profile_pic,
                    id: data.data.wallet.account_id,
                    wallet: {
                        coins: data.data.wallet.coins,
                        rubies: data.data.wallet.rubies,
                    },
                });
                setShowWallet(true);
            }
        };
        fetchAccount();
    }, []);

    useEffect(() => {
        setIframeSrc(`${process.env.GAMES_URL}?userId=${userAccount.id}&userName=${userAccount.username}&profilePic=${encodeURIComponent(userAccount.profile_pic)}&sessionToken=${session_token}`);
    }, [userAccount]);

    return (
        <div
            id='content'
            className='min-h-[75vh] mt-[0px] flex flex-col'
        >
            <WalletDisplay
                username={userAccount.username}
                profilePic={userAccount.profile_pic}
                wallet={userAccount.wallet}
                showWallet={showWallet}
            />
            <div className='flex flex-grow w-full h-full min-h-full'>
                <iframe
                    title='squaresIframe'
                    src={iframeSrc}
                    frameBorder='0'
                    style={{display: 'flex', flexGrow: 1}}
                    className='border-0'
                ></iframe>
            </div>
        </div>
    );
};

export default Squares;
