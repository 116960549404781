import React from 'react';

const WalletDisplay = ({ profilePic, username, wallet, showWallet }) => {

  return (
    <>
      {showWallet ? (
        <div className='relative w-full h-[56px]'>
          <div className='absolute w-full h-[56px] top-0 left-0 bg-radial-gradient opacity-60' />
          <div className='absolute top-0 left-0 w-full px-[15px] py-[8px]'>
            <div className='flex'>
              <div className='w-[50px] border'>
                <img alt='Profile' src={profilePic} className='h-[40px]' />
              </div>
              <div className='flex-grow font-anton text-white pt-[11px] text-[18px] pl-[10px] truncate'>
                {username}
              </div>

              <div className='flex-end font-anton text-white text-[18px] px-[20px] h-[40px] bg-subhead flex mr-[3px]'>
                <img
                  alt='Diamond'
                  src={`${process.env.CDN_ENDPOINT}/assets/single_diamond.png`}
                  className='h-[30px] mt-[5px]'
                />
                <div className='pt-[11px] pl-[10px]'>{wallet.rubies}</div>
              </div>
              <div className='flex-end font-anton text-white text-[18px] px-[20px] h-[40px] bg-subhead flex'>
                <img
                  alt='Coins'
                  src={`${process.env.CDN_ENDPOINT}/assets/single_coin.png`}
                  className='h-[30px] mt-[5px]'
                />
                <div className='pt-[11px] pl-[10px]'>{wallet.coins}</div>
              </div>
              <div className='bg-lime h-[40px] w-[40px] font-anton text-main text-center text-[14px] font-normal pt-[13px]'>
                SHOP
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default WalletDisplay;
